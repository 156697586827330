define("backend/helpers/translate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.translate = translate;
  _exports.default = void 0;
  var ENGLISH_FIELDS = {
    email: 'E-Mail',
    vat: 'VAT',
    zip: 'ZIP',
    iban: 'IBAN',
    bic: 'BIC',
    titlePrefix: 'Title',
    titleSuffix: 'Title',
    firstName: 'First Name',
    lastName: 'Last Name',
    companyName: 'Company Name',
    companyDepartment: 'Department',
    startDate: 'Start Date',
    studiesPartner: 'Studies Partner',
    // DEVICE
    serialNumber: 'Serial Number',
    deviceVersion: 'Device Version',
    deviceModel: 'Device Model',
    deliveryDate: 'Assembly Date',
    returnDate: 'Return Date',
    ownerType: 'Type',
    licenseStatus: 'License Status',
    'app.version': 'App Version',
    lastUpdate: 'Last Content Update',
    lastRequest: 'Last Request',
    failedUpdateAttempts: 'Failed Update Attempts',
    wifiName: 'WiFi',
    debugMode: 'Debug Mode',
    mobileData: 'Mobile Data',
    movieFileInfos: 'Movies',
    storageUsedMB: 'Storage Used',
    storageFreeMB: 'Storage Free',
    batteryInPercent: 'Battery Level',
    ipLocation: 'IP Location',
    ipAddress: 'IP Address',
    downloadSpeedFormatted: 'Download Speed',
    // DEVICE PACKAGE
    sellingPrice: 'Selling Price',
    expirationDate: 'Expiration Date',
    // LEAD
    leadType: 'Lead Type',
    bestReachability: 'Best Reachability',
    // CLIENT
    clientNumber: 'Client Number',
    // BANK
    paymentMethod: 'Method',
    paymentInterval: 'Interval',
    // BILLING
    discount: 'Discount (€)',
    skonto: 'Skonto %',
    skontoTime: 'Skonto Time Days',
    paymentTarget: 'Payment Target',
    // ATTACHMENT
    documentDate: 'Document Date',
    // ADDRESS
    address1: 'Address',
    address2: '',
    address3: '',
    // MOVIE
    targetGroup: 'Target Group',
    revenueShare: 'Revenue Share'
  };

  function translate(key) {
    var translation = ENGLISH_FIELDS[key];

    if (!Ember.isNone(translation)) {
      return translation;
    }

    return Ember.String.capitalize(key);
  }

  var _default = Ember.Helper.helper(translate);

  _exports.default = _default;
});