define("backend/pods/components/hm-models/list/row-w-departments/component", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    tagName: '',
    rowsGroupByClientGroup: Ember.computed('rows.length', function () {
      var rows = this.get('rows');
      var groupIds = rows.filterBy('selected.isClientGroup').mapBy('selected.id');
      return rows.filter(function (row) {
        return !groupIds.includes(row.selected.get('clientGroup.id'));
      });
    }),
    _urlForRoute: function _urlForRoute(route, id) {
      var url;

      if (id) {
        url = (0, _emberInflector.pluralize)(route) + '/' + id;
      }

      debugger;

      switch (route) {
        case 'client':
        case 'sponsored-client':
        case 'lead':
          url += '/essentials';
          break;
      }

      return url;
    },
    actions: {
      clickRow: function clickRow(owner, evt) {
        var route = this.get('clickRoute');

        if (!route) {
          if (owner.get('departments').isAny('status.isClientStatus')) {
            route = 'client';
          } else if (owner.get('departments').isAny('status.isSponsoredClientStatus')) {
            route = 'sponsored-client';
          } else {
            route = 'lead';
          }
        }

        var newURL = [window.location.protocol + '//' + window.location.host, this._urlForRoute(route, owner.get('id'))].join('/'); // Always open in a new tab

        var win = window.open(newURL, '_blank');

        if (win) {
          win.focus();
        }
      }
    }
  });

  _exports.default = _default;
});