define("backend/utils/static-data/contract-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'Video Glasses'
  }, {
    id: 2,
    name: 'EasyTv'
  }, {
    id: 3,
    name: 'EasyMovie'
  }, {
    id: 4,
    name: 'hypnoVR'
  }];
  _exports.default = _default;
});