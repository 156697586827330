define("backend/pods/leads/filter-not-synced/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6YqIBIKG",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-header\",null,[[\"title\",\"additional\"],[\"Leads: Not synced to Close\",[24,[\"tName\"]]]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"style\",\"font-size: 16px; font-weight: bold; margin-bottom: 10px; margin-left: 30px; color: #333;\"],[8],[0,\"\\n  \"],[1,[24,[\"filteredModel\",\"length\"]],false],[0,\" / \"],[1,[24,[\"model\",\"length\"]],false],[0,\" \\n\"],[9],[0,\"\\n\\n\"],[4,\"panel-container\",null,null,{\"statements\":[[0,\"  \"],[1,[22,\"hm-models/create/leads\"],false],[0,\"\\n  \"],[1,[28,\"hm-models/list/filter-leads\",null,[[\"model\"],[[23,0,[]]]]],false],[0,\"\\n  \"],[1,[28,\"hm-models/list/table-clickable\",null,[[\"ownerType\",\"data\",\"isLoading\",\"rowComponent\",\"page\",\"size\",\"tableColumns\",\"defaultClientSort\"],[\"lead\",[24,[\"filteredModel\"]],[24,[\"filteredModel\",\"isLoading\"]],[28,\"component\",[\"hm-models/list/row-w-departments\"],null],[24,[\"page\"]],[24,[\"size\"]],[24,[\"tableColumns\"]],[24,[\"clientSort\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/leads/filter-not-synced/template.hbs"
    }
  });

  _exports.default = _default;
});