define("backend/pods/components/hm-form/select-value/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function flattenOptionGroup(acc, optionOrGroup) {
    if (optionOrGroup.get && optionOrGroup.get('options')) {
      optionOrGroup.get('options').forEach(function (option) {
        if (option.get('options')) {
          flattenOptionGroup(acc, option);
        } else {
          acc.push(option);
        }
      });
    } else {
      acc.push(optionOrGroup);
    }

    return acc;
  }

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.setProperties({
        attrValue: this.get('selectValue') || 'id',
        attrLabel: this.get('selectLabel') || 'displayName'
      });
    },
    dataFlattened: Ember.computed('data.length', function () {
      return (this.get('data') || []).reduce(flattenOptionGroup, []);
    }),
    actions: {
      setValue: function setValue(option) {
        console.log("setValue");
        var value = !Ember.isNone(option) ? option.get && option.get(this.get('attrValue')) : null;

        var _this$getProperties = this.getProperties(['model', 'field', 'markAsEdited']),
            model = _this$getProperties.model,
            field = _this$getProperties.field,
            markAsEdited = _this$getProperties.markAsEdited;

        if (model.set) {
          model.set(field, value);
        } else {
          model[field] = value;
        }

        if (this.get('acts.setAction')) {
          this.get('acts.setAction')(option);
        }
      },
      onOpen: function onOpen() {
        if (this.get('acts.openAction')) {
          this.get('acts.openAction')();
        }
      }
    }
  });

  _exports.default = _default;
});