define("backend/models/client-like", ["exports", "ember-data", "ember-cp-validations", "backend/models/buffered-model", "backend/mixins/person-mixin", "backend/mixins/company-mixin", "backend/mixins/contact-details-mixin"], function (_exports, _emberData, _emberCpValidations, _bufferedModel, _personMixin, _companyMixin, _contactDetailsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = _bufferedModel.default.extend(_personMixin.default, _companyMixin.default, _contactDetailsMixin.default, {
    currentUser: Ember.inject.service(),
    vat: attr('string'),
    notes: attr('string'),
    startDate: attr('string'),
    displayName: attr('string'),
    syncedToClose: attr('number'),
    toBeReviewed: attr('number'),
    sponsorship: attr('string'),
    displayNameId: Ember.computed('displayName', function () {
      return "".concat(this.get('displayName'), " (").concat(this.get('id'), ")");
    }),
    billingInformation: attr('string'),
    counts: attr(),
    distributor: belongsTo('distributor', {
      async: true
    }),
    address: belongsTo('address', {
      async: false,
      useBuffer: true
    }),
    type: belongsTo('clientType', {
      async: false
    }),
    clientGroup: belongsTo('client-like', {
      async: true,
      inverse: null
    }),
    sources: hasMany('source', {
      async: false
    }),
    contacts: hasMany('contact', {
      async: true,
      useBuffer: true
    }),
    departments: hasMany('department', {
      async: false,
      useBuffer: true
    }),
    devices: hasMany('device', {
      async: true
    }),
    events: hasMany('event', {
      async: true
    }),
    tasks: hasMany('hm-task', {
      async: true
    }),
    studies: hasMany('study', {
      async: true
    }),
    attachments: hasMany('attachment', {
      async: true
    }),
    descendants: hasMany('client-like', {
      async: true,
      inverse: null
    }),
    billings: hasMany('billing', {
      async: true
    }),
    // ---------------------------- BUFFER ---------------------------------------
    supportsDirtyBufferTracking: true,
    createDependencies: function createDependencies() {
      this._super();

      if (this.get('isNew')) {
        return this._setUsersDistributor();
      }
    },
    _setUsersDistributor: function _setUsersDistributor() {
      var _this = this;

      var currentUser = this.get('currentUser.user');

      if (currentUser && currentUser.then) {
        return currentUser.then(function (user) {
          _this.set('distributor', user.get('distributor'));
        });
      }

      return Ember.RSVP.resolve();
    },
    // --------------------------- READONLY --------------------------------------
    isClientGroup: Ember.computed.gt('descendants.length', 0),
    hasMultipleDepartments: Ember.computed.gt('departments.length', 1),
    departmentsNames: Ember.computed('departments.length', function () {
      var departments = this.get('departments').map(function (department) {
        if (department.get('subName')) {
          return "".concat(department.get('name'), " - ").concat(department.get('subName'));
        }

        return department.get('name');
      });
      return "".concat(departments.join(', '), " (").concat(departments.length, ")");
    }),
    sortedEvents: Ember.computed.sort('events', 'sortDateDesc'),
    sortDateDesc: ['timestamp:desc'],
    indexRoute: Ember.computed('departments.length', function () {
      if (this.get('departments').isAny('status.isClientStatus')) {
        return 'client';
      } else if (this.get('departments').isAny('status.isSponsoredClientStatus')) {
        return 'sponsored-client';
      }

      return 'lead';
    }),
    markerLabel: Ember.computed('departments.length', function () {
      var count = this.get('departments.length');

      if (count > 1) {
        return '' + count;
      }

      return null;
    }),
    ordersCount: Ember.computed('counts.orders', 'counts.material_orders', function () {
      var _this$get, _this$get2;

      return ((_this$get = this.get('counts.orders')) !== null && _this$get !== void 0 ? _this$get : 0) + ((_this$get2 = this.get('counts.material_orders')) !== null && _this$get2 !== void 0 ? _this$get2 : 0);
    }),
    isStudyPartner: Ember.computed('departments.@each.isStudyPartner', function () {
      return this.departments.toArray().some(function (department) {
        return department.isStudyPartner;
      });
    }),
    getTax: function getTax() {
      if (Ember.isBlank(this.vat)) {
        return 20;
      }

      return 0;
    },
    // --------------------------- ACTIONS ---------------------------------------
    saveBillingInformation: function saveBillingInformation(billingInformation) {
      this.set('billingInformation', billingInformation);
      return this.save();
    },
    // -------------------------- VALIDATIONS ------------------------------------
    validators: {
      type: (0, _emberCpValidations.validator)('presence', true)
    }
  });

  _exports.default = _default;
});