define("backend/pods/components/hm-model/mixins/department-status", ["exports", "backend/utils/static-data/department-names", "backend/utils/static-data/department-sources", "backend/utils/dropdown-options/sales-closing-months"], function (_exports, _departmentNames, _departmentSources, _salesClosingMonths) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    allowedDepartmentNames: _departmentNames.default,
    departmentSources: _departmentSources.default,
    closingProbabilities: [Ember.Object.create({
      id: '10',
      displayName: '10% low'
    }), Ember.Object.create({
      id: '40',
      displayName: '40% let\'s see'
    }), Ember.Object.create({
      id: '60',
      displayName: '60% optimistic'
    }), Ember.Object.create({
      id: '90',
      displayName: '90% almost signed'
    })],
    closingMonths: Ember.computed(function () {
      return (0, _salesClosingMonths.createClosingMonthOptionsToSupportExisting)();
    }),
    clientAndLeadStatusList: Ember.computed(function () {
      var status = this.get('store').peekAll('department-status').filter(function (status) {
        return status.isClientStatus || status.isLeadStatus;
      });

      if (this.get('statusForLeadOnly')) {
        return status.filterBy('isClientStatus', false).filterBy('isSelectable');
      } else if (this.get('statusForClientOnly')) {
        return status.filterBy('isClientStatus').filterBy('isSelectable');
      }

      return status.reduce(function (acc, status) {
        if (!status.get('isSelectable')) {
          return acc;
        }

        if (status.get('isClientStatus')) {
          acc.findBy('id', 'client').get('options').push(status);
        } else {
          acc.findBy('id', 'lead').get('options').push(status);
        }

        return acc;
      }, [Ember.Object.create({
        id: 'lead',
        groupName: 'Lead Status',
        options: []
      }), Ember.Object.create({
        id: 'client',
        groupName: 'Client Status',
        options: []
      })]);
    }),
    leadStatusList: Ember.computed(function () {
      return this.get('store').peekAll('department-status').filterBy('isLeadStatus').filterBy('isSelectable');
    }),
    leadStatusListForNew: Ember.computed(function () {
      return this.get('store').peekAll('department-status').filterBy('isLeadStatus').filterBy('isSelectable').filterBy('requiresNextStep', false);
    }),
    clientStatusList: Ember.computed(function () {
      return this.get('store').peekAll('department-status').filterBy('isClientStatus').filterBy('isSelectable');
    }),
    sponsoredClientStatusList: Ember.computed(function () {
      return this.get('store').peekAll('department-status').filterBy('isSponsoredClientStatus').filterBy('isSelectable');
    }),
    // ----------------------------- REASONS -------------------------------------
    existingReasons: Ember.computed('keywords.reasons.length', function () {
      return this.get('keywords.reasons').toArray() || [];
    }),
    actions: {
      setStartDate: function setStartDate(buffer, isoDate) {
        buffer.setStartDate(isoDate);
      },
      setEndDate: function setEndDate(buffer, isoDate) {
        buffer.setEndDate(isoDate);
      },
      addNewReason: function addNewReason(department, name) {
        this.get('store').createRecord('reason', {
          name: name
        });
        Ember.run.schedule('afterRender', function () {
          return department.set('statusNote', name);
        });
      },
      selectStatus: function selectStatus(department) {},
      getDepartmentOptions: function getDepartmentOptions(department) {
        var statusList;

        if (!Ember.isNone(department.get('status'))) {
          if (department.get('status.isClientStatus')) {
            statusList = 'clientStatusList';
          } else if (department.get('status.isLeadStatus')) {
            statusList = 'leadStatusList';
          } else if (department.get('status.isSponsoredClientStatus')) {
            statusList = 'sponsoredClientStatusList';
          }
        } else if (this.get('model').constructor.modelName === 'lead') {
          statusList = 'leadStatusList';
        } else if (this.get('model').constructor.modelName === 'client') {
          statusList = 'clientAndLeadStatusList';
        } else if (this.get('model').constructor.modelName === 'sponsored-client') {
          statusList = 'sponsoredClientStatusList';
        }

        return this.get(statusList);
      },
      getDepartmentSources: function getDepartmentSources(department) {
        // Here, you could add any department-specific logic if needed
        // For now, returning the static list
        return ['Website', 'Patients', 'LinkedIn', 'Referral', 'Data Import', 'Inno Q2/22', 'Inno Q3/22', 'Inno Q1/23', 'EndoCert Q3/23', 'EndoCert Q4/23', 'Inno Q3/24', 'Inno Q4/24', 'Inno Q1/25'];
      },
      selectSource: function selectSource(department, source) {
        console.log("Selected source for ".concat(department, ": ").concat(source)); // Handle the source selection logic here
      }
    }
  });

  _exports.default = _default;
});