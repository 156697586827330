define("backend/pods/components/hm-model/fieldset-departments/departments/component", ["exports", "backend/pods/components/hm-model/mixins/department-status", "backend/config/environment"], function (_exports, _departmentStatus, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_departmentStatus.default, {
    keywords: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    // Inject router service
    actions: {
      createDepartment: function createDepartment() {
        this.get('model').addDepartment();
      },

      /*
      removeDepartment(department) {
        console.log("removeDepartment department:", department);
        this.get('model').removeDepartment(department);
      },
      */
      removeDepartment: function removeDepartment(department) {
        console.log('fieldset-contacts removeDepartment');
        var confirmed = window.confirm("Are you sure you want to delete this department?");

        if (confirmed) {
          console.log('Deleting department:', department);
          this.get('model').removeDepartment(department);
          this.send('saveDepartment', department, -1);
        }
      },
      markAsEdited: function markAsEdited(department) {
        //console.log("markAsEdited triggered for department:", department);
        department.set('isEdited', true); // Track edits per contact
      },
      saveDepartment: function saveDepartment(department, status) {
        var _this = this;

        console.log('fieldset-departmens saveDepartment:', department);
        department.set('isSaving', true);
        var departmentId = department.get('id');
        console.log('fieldset-departmens departmentId:', departmentId);

        if (!departmentId) {
          var _currentRoute$parent, _currentRoute$parent$, _currentRoute$parent2, _currentRoute$parent3;

          console.error('No valid ID found for the Department. Update request cannot be sent.');
          var currentRoute = this.router.currentRoute; //console.log('saveDepartment currentRoute:', currentRoute);
          //console.log('saveDepartment currentRoute.parent:', currentRoute.parent);
          //console.log('saveDepartment currentRoute.parent.params.lead_id:', currentRoute.parent.params.lead_id);
          // Extract lead or client ID from parent route params

          var leadId = (_currentRoute$parent = currentRoute.parent) === null || _currentRoute$parent === void 0 ? void 0 : (_currentRoute$parent$ = _currentRoute$parent.params) === null || _currentRoute$parent$ === void 0 ? void 0 : _currentRoute$parent$.lead_id;
          var clientId = (_currentRoute$parent2 = currentRoute.parent) === null || _currentRoute$parent2 === void 0 ? void 0 : (_currentRoute$parent3 = _currentRoute$parent2.params) === null || _currentRoute$parent3 === void 0 ? void 0 : _currentRoute$parent3.client_id; // Use the available ID (lead first, then client)

          var id = leadId || clientId; //console.log('Extracted ID:', id);
          //throw new Error('No ID found for contact.');

          var url = "".concat(_environment.default.host, "/departments/new/").concat(id);
          console.log('fieldset-departmens saveDepartment url:', url);
          var content = JSON.parse(JSON.stringify(department.content));
          Object.assign(content, department.buffer);
          console.log('fieldset-departmens saveDepartment content:', content);
          var newObj = {};
          Object.keys(content).forEach(function (key) {
            // Convert camelCase to snake_case
            var newKey = key.replace(/([A-Z])/g, "_$1").toLowerCase();
            newObj[newKey] = content[key];
          });
          newObj['status'] = status; //department.buffer.status?.id ?? department.buffer.status;

          console.log('saveNewDepartment newObj:', newObj);
          return new Promise(function (resolve, reject) {
            _this.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
              var headers = {};
              headers[headerName] = headerValue;

              _this.get('ajax').request(url, {
                method: 'POST',
                data: {
                  data: newObj
                },
                // Send merged content
                contentType: 'application/json',
                dataType: 'json',
                headers: headers
              }).then(function () {
                console.log("Department with ID ".concat(departmentId, " saved successfully."));
                department.setProperties({
                  isSaved: true,
                  isSaving: false,
                  isEdited: false // Reset only for this contact

                });
                window.location.reload();
                /*
                setTimeout(() => {
                  department.set('isSaved', false);
                  window.location.reload();
                }, 3000);
                */

                resolve();
              }).catch(function (_ref) {
                var error = _ref.payload.error;
                console.error("Error saving department with ID ".concat(departmentId, ":"), error);

                _this.set('errorMessage', error);

                reject(error);
              });
            });
          });
        } else {
          var _url = "".concat(_environment.default.host, "/departments/").concat(departmentId);

          console.log('fieldset-departmens saveDepartment url:', _url);

          var _content = JSON.parse(JSON.stringify(department.content));

          Object.assign(_content, department.buffer);
          console.log(_content);
          var _newObj = {};
          Object.keys(_content).forEach(function (key) {
            // Convert camelCase to snake_case
            var newKey = key.replace(/([A-Z])/g, "_$1").toLowerCase();
            _newObj[newKey] = _content[key];
          }); //newObj['status'] = status;  // OR newObj.status = status;

          _newObj['status'] = status; //department.buffer.status?.id ?? department.buffer.status;

          console.log('Updated content to be saved:', _newObj);
          return new Promise(function (resolve, reject) {
            _this.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
              var headers = {};
              headers[headerName] = headerValue;

              _this.get('ajax').request(_url, {
                method: 'PUT',
                data: {
                  data: _newObj
                },
                // Send merged content
                contentType: 'application/json',
                dataType: 'json',
                headers: headers
              }).then(function () {
                console.log("Department with ID ".concat(departmentId, " saved successfully."));
                department.setProperties({
                  isSaved: true,
                  isSaving: false,
                  isEdited: false // Reset only for this contact

                });
                window.location.reload();
                /*
                setTimeout(() => {
                  department.set('isSaved', false);
                  window.location.reload();
                }, 3000);
                */

                resolve();
              }).catch(function (_ref2) {
                var error = _ref2.payload.error;
                console.error("Error saving department with ID ".concat(departmentId, ":"), error);

                _this.set('errorMessage', error);

                reject(error);
              });
            });
          });
        } //this.get('model').saveDepartment(department);

      }
    }
  });

  _exports.default = _default;
});