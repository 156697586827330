define("backend/models/billing", ["exports", "ember-data", "backend/models/buffered-model", "backend/utils/current-day-utc", "ember-cp-validations", "moment", "backend/helpers/format-currency", "backend/models/simple/billing-position"], function (_exports, _emberData, _bufferedModel, _currentDayUtc, _emberCpValidations, _moment, _formatCurrency, _billingPosition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  function addMaterialOrderPositions(materialOrder, positions, language) {
    var headphoneCovers = materialOrder.headphoneCovers,
        individualProducts = materialOrder.individualProducts,
        marketingMaterials = materialOrder.marketingMaterials,
        shipping = materialOrder.shipping;

    if (headphoneCovers) {
      var headphoneCoversPos = _billingPosition.default.create({
        posNo: positions.length + 1,
        language: language
      });

      headphoneCoversPos.setLanguage(language);
      headphoneCoversPos.set('artNo', headphoneCovers);
      positions.push(headphoneCoversPos);
    }

    if (individualProducts) {
      var prodIds = individualProducts.split(',');
      prodIds.forEach(function (individualProduct) {
        var individualProductPos = _billingPosition.default.create({
          posNo: positions.length + 1,
          language: language
        });

        individualProductPos.setLanguage(language);
        individualProductPos.set('artNo', individualProduct);
        positions.push(individualProductPos);
      });
    }

    if (marketingMaterials) {
      var _prodIds = marketingMaterials.split(',');

      _prodIds.forEach(function (marketingMaterial) {
        var marketingMaterialPos = _billingPosition.default.create({
          posNo: positions.length + 1,
          language: language
        });

        marketingMaterialPos.setLanguage(language);
        marketingMaterialPos.set('artNo', marketingMaterial);
        positions.push(marketingMaterialPos);
      });
    }

    if (shipping) {
      var shippingPos = _billingPosition.default.create({
        posNo: positions.length + 1,
        language: language
      });

      shippingPos.setLanguage(language);
      shippingPos.set('artNo', shipping === 'SHIPPING-15' ? '1PP-01' : '1PP-02');
      positions.push(shippingPos);
    }
  }

  function addRepairOrderPositions(repairOrder, positions, language) {
    var billingTaskText = repairOrder.billingTaskText,
        billingTaskPrice = repairOrder.billingTaskPrice;

    var pos = _billingPosition.default.create({
      posNo: positions.length + 1,
      language: language
    });

    pos.setLanguage(language);
    pos.set('artNo', '4N-D1');
    pos.set('description', billingTaskText);
    pos.set('netUnitPrice', billingTaskPrice);
    positions.push(pos);
  }

  var _default = _bufferedModel.default.extend({
    owner: belongsTo('client-like', {
      async: true,
      polymorphic: true
    }),
    ownerMeta: attr(),
    status: belongsTo('billing-status', {
      async: false
    }),
    address: attr('string'),
    contact: attr('string'),
    emails: attr(),
    // ('invoice' | 'reminder1' | 'reminder2' | 'reminder3')[]
    date: attr('string', {
      defaultValue: (0, _currentDayUtc.default)()
    }),
    periodStart: attr('string'),
    periodEnd: attr('string'),
    paymentTarget: attr('string', {
      defaultValue: '10'
    }),
    paymentMethod: attr('string', {
      defaultValue: 'B'
    }),
    text: attr('string'),
    symbol: attr('string', {
      defaultValue: '€'
    }),
    language: attr('string', {
      defaultValue: 'deu'
    }),
    positions: attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    tax: attr('number', {
      defaultValue: '20'
    }),
    discount: attr('number', {
      defaultValue: 0
    }),
    skonto: attr('number', {
      defaultValue: 0
    }),
    skontoTime: attr('number', {
      defaultValue: 14
    }),
    totalAmount: attr('number'),
    emailSubject: attr('string'),
    emailTo: attr('string'),
    dateOfShipment: attr('string'),
    dateOfPayment: attr('string'),
    dateOfCancellation: attr('string'),
    file: attr('string'),
    cancellation: attr('string'),
    number: attr('string'),
    totalAmountNet: attr('number'),
    totalAmountGross: attr('number'),
    totalStornoNet: attr('number'),
    totalStornoGross: attr('number'),
    templateTitle: attr('string'),
    templateType: belongsTo('billing-template-type', {
      async: false
    }),
    displayName: Ember.computed('templateType.name', 'templateTitle', function () {
      return "".concat(this.templateType.name, " - ").concat(this.templateTitle);
    }),
    contract: belongsTo("contract", {
      async: true
    }),
    template: belongsTo("billing", {
      async: true
    }),
    // -------------------------- READONLY ---------------------------------------
    ownerType: Ember.computed.reads('owner.ownerType'),
    overdueAt: Ember.computed('date', 'paymentTarget', function () {
      var _this$getProperties = this.getProperties('date', 'paymentTarget'),
          date = _this$getProperties.date,
          paymentTarget = _this$getProperties.paymentTarget;

      if (!paymentTarget) {
        paymentTarget = 0;
      }

      if (!date) {
        return null;
      }

      return (0, _moment.default)(date).add(paymentTarget, 'days').format('YYYY-MM-DD');
    }),
    overdueSince: Ember.computed('overdueAt', function () {
      return (0, _moment.default)().startOf('day').diff((0, _moment.default)(this.get('overdueAt')), 'days');
    }),
    overduePhase: Ember.computed('overdueSince', function () {
      var since = this.get('overdueSince');

      if (!since) {
        return null;
      }

      if (since > 40) {
        return {
          title: 'Lawyer',
          label: 'label-danger'
        };
      }

      if (since > 30) {
        return {
          title: 'Second warning',
          label: 'label-danger'
        };
      }

      if (since > 20) {
        return {
          title: 'First warning',
          label: 'label-warning'
        };
      }

      if (since > 0) {
        return {
          title: 'Tolerance time',
          label: 'label-warning'
        };
      }

      return null;
    }),
    zusatz: Ember.computed('text', function () {
      return this.text;
    }),
    reverseCharge: Ember.computed('text', function () {
      var text = (this.text || '').toLowerCase();

      if (text.includes('reverse charge')) {
        return 'Yes';
      }

      return 'No';
    }),
    shortName: Ember.computed('status.isTemplate', 'status.name', 'number', function () {
      if (this.status.get('isTemplate')) {
        return "Billing Template";
      }

      var diff = (0, _moment.default)(this.get('periodEnd')).add(1, 'day').diff(this.get('periodStart'), 'months');
      return "".concat((0, _moment.default)(this.get('periodStart')).format('DD.MM.YYYY'), " - ").concat((0, _moment.default)(this.get('periodEnd')).format('DD.MM.YYYY'), " (").concat(diff === 0 ? '' : diff + ' months, ', "date: ").concat((0, _moment.default)(this.get('date')).format('DD.MM.YYYY'), ", total gross: ").concat((0, _formatCurrency.formatCurrency)([this.get('totalAmountGross')]), ")");
    }),
    // -------------------------- BUFFER -----------------------------------------
    prefill: function prefill(prefillTemplate) {
      var buffer = this.get('buffers.selected');

      if (buffer && prefillTemplate) {
        var prefillData = prefillTemplate.getProperties('periodStart', 'periodEnd', 'owner', 'contact', 'contract', 'materialOrder', 'repairOrder');
        var billingTemplate = prefillTemplate.get('template.content');

        if (billingTemplate) {
          var _billingTemplate$get;

          var positions = [];
          var text = (_billingTemplate$get = billingTemplate.get('text')) !== null && _billingTemplate$get !== void 0 ? _billingTemplate$get : '';

          if (prefillData.contract.content) {
            var isFirstContractBilling = prefillData.contract.get('deliveryDate') === prefillData.periodStart; // Include setup fee only in first billing

            positions = isFirstContractBilling ? billingTemplate.get('positions').map(function (position) {
              return (0, _billingPosition.clonePosition)(position);
            }) : billingTemplate.get('positions').filter(function (position) {
              return position.get('artNo') !== '1S-02';
            }).map(function (position) {
              return (0, _billingPosition.clonePosition)(position);
            });
          } else if (prefillData.materialOrder) {
            positions = [];
            addMaterialOrderPositions(prefillData.materialOrder, positions, billingTemplate.get('language'));
          } else if (prefillData.repairOrder) {
            positions = [];
            addRepairOrderPositions(prefillData.repairOrder, positions, billingTemplate.get('language'));

            if (prefillData.repairOrder.billingTaskAdditionalText) {
              text += "\n".concat(prefillData.repairOrder.billingTaskAdditionalText);
            }
          }

          buffer.setProperties(_objectSpread({}, prefillData, {}, billingTemplate.getProperties('address', 'contact', 'paymentTarget', 'paymentMethod', 'symbol', 'language', 'tax', 'discount', 'totalAmount', 'contract'), {
            template: billingTemplate,
            positions: positions,
            text: text
          }));
        } else if (prefillData.materialOrder) {
          var _positions = [];
          addMaterialOrderPositions(prefillData.materialOrder, _positions, 'deu');
          buffer.setProperties(_objectSpread({}, prefillData, {
            positions: _positions
          }));
        } else if (prefillData.repairOrder) {
          var _positions2 = [];
          addRepairOrderPositions(prefillData.repairOrder, _positions2, 'deu');

          if (prefillData.repairOrder.billingTaskAdditionalText) {
            var _buffer$get;

            buffer.set('text', "".concat((_buffer$get = buffer.get('text')) !== null && _buffer$get !== void 0 ? _buffer$get : '', "\n").concat(prefillData.repairOrder.billingTaskAdditionalText));
          }

          buffer.setProperties(_objectSpread({}, prefillData, {
            positions: _positions2
          }));
        } else {
          buffer.setProperties(prefillData);
        }
      }
    },
    // -------------------------- ACTIONS ----------------------------------------
    saveShipmentDate: function saveShipmentDate(shipmentDate) {
      var adapter = this.get('store').adapterFor('billing');
      return adapter.updateShipmentDate(this.get('id'), shipmentDate);
    },
    savePaymentDate: function savePaymentDate(paymentDate) {
      var adapter = this.get('store').adapterFor('billing');
      return adapter.updatePaymentDate(this.get('id'), paymentDate);
    },
    saveCancellation: function saveCancellation() {
      var adapter = this.get('store').adapterFor('billing');
      return adapter.updateCancellationDate(this.get('id'), new Date());
    },
    saveContract: function saveContract(contract) {
      var adapter = this.get('store').adapterFor('billing');
      return adapter.updateContract(this.get('id'), contract);
    },
    // -------------------------- VALIDATIONS ------------------------------------
    validators: {
      address: (0, _emberCpValidations.validator)('presence', true),
      paymentTarget: (0, _emberCpValidations.validator)('presence', true),
      paymentMethod: (0, _emberCpValidations.validator)('presence', true),
      tax: (0, _emberCpValidations.validator)('presence', true)
    }
  });

  _exports.default = _default;
});