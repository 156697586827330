define("backend/pods/sales-cockpit/jour-fixe/controller", ["exports", "backend/helpers/tables/table-group-header"], function (_exports, _tableGroupHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['distributor', 'start', 'end'],
    distributor: null,
    start: null,
    end: null,
    tableColumnsActivities: (0, _tableGroupHeader.default)([{
      group: {
        name: 'Event'
      },
      columns: [{
        attr: 'timestamp',
        name: 'Date',
        style: 'text-nowrap'
      }, {
        attr: 'type',
        name: 'Type',
        style: 'text-nowrap'
      }, {
        attr: 'reference',
        name: 'Reference'
      }, {
        attr: 'notes',
        name: 'Notes',
        format: 'log'
      }]
    }, {
      group: {
        name: 'Lead/Client'
      },
      columns: [{
        attr: 'display_name',
        name: 'Name',
        component: 'hm-models/list/link-plain-owner'
      }, {
        attr: 'departments_names',
        name: 'Departments'
      }, {
        attr: 'departments_status',
        name: 'Status'
      }, {
        attr: 'client_type',
        name: 'Client Type',
        display: false
      }, {
        attr: 'lead_type',
        name: 'Lead Type',
        display: false
      }, {
        attr: 'synced_to_close',
        name: 'Synced To Close',
        display: false
      }, {
        attr: 'to_be_reviewed',
        name: 'To be reviewed',
        display: false
      }, {
        attr: 'sponsorship',
        name: 'Sponsorship',
        display: false
      }, {
        attr: 'rating',
        display: false
      }, {
        attr: 'email',
        name: 'E-Mail',
        display: false
      }, {
        attr: 'phone',
        display: false
      }, {
        attr: 'website',
        display: false
      }, {
        attr: 'source',
        display: false
      }]
    }, {
      group: {
        name: 'Lead/Client Address'
      },
      columns: [{
        attr: 'address_1',
        name: 'Address',
        style: 'text-nowrap',
        display: false
      }, {
        attr: 'zip',
        style: 'text-nowrap',
        display: false
      }, {
        attr: 'city',
        style: 'text-nowrap',
        display: false
      }, {
        attr: 'country',
        style: 'text-nowrap',
        display: false
      }]
    }]),
    eventTypes: Ember.computed(function () {
      return this.get('store').peekAll('event-type');
    }),
    filteredActivities: Ember.computed('model.activities', 'selEventTypes.length', function () {
      var filters = (this.get('selEventTypes') || []).mapBy('id');

      if (filters.get('length') > 0) {
        return (this.get('model.activities') || []).filter(function (activity) {
          return filters.includes('' + activity.type_id);
        });
      }

      return this.get('model.activities') || [];
    })
  });

  _exports.default = _default;
});