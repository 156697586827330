define("backend/components/route-component", ["exports", "ember-data", "backend/config/environment", "backend/mixins/validation-mixin", "backend/mixins/form-contacts-mixin", "backend/mixins/form-attachments-mixin", "backend/mixins/form-departments-mixin", "ember-inflector"], function (_exports, _emberData, _environment, _validationMixin, _formContactsMixin, _formAttachmentsMixin, _formDepartmentsMixin, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ObjectPromiseProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);

  var _default = Ember.Component.extend(_validationMixin.default, _formContactsMixin.default, _formAttachmentsMixin.default, _formDepartmentsMixin.default, {
    saveCompleteAction: 'saveComplete',
    deleteCompleteAction: 'deleteComplete',
    cancelCompleteAction: 'cancelComplete',
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    table: Ember.computed('data', function () {
      var modelPluralized = this.get('modelNamePluralized');
      var model = this.get("data.".concat(modelPluralized));
      return model || this.get('data');
    }),
    templateEditEntry: 'partials/edit-entry',
    templateListEntries: 'partials/list-entries',
    templateList: Ember.computed('createModel', function () {
      return "components/tables/" + this.get('modelNamePluralized');
    }),
    templateEdit: Ember.computed('createModel', function () {
      return "components/forms/" + this.get('createModel');
    }),
    labelCreate: Ember.computed('createModel', function () {
      return "Create " + this.get('modelName');
    }),
    showEntries: true,
    canCreate: true,
    layoutName: 'components/route-component',
    selectedEmpty: Ember.computed.empty('selected'),
    showCreate: Ember.computed.and('selectedEmpty', 'canCreate'),
    labelHeader: Ember.computed.reads('selected.displayName'),
    modelName: Ember.computed('createModel', function () {
      var modelName = this.get('createModel');
      return modelName.split('-').map(function (str) {
        return Ember.String.capitalize(str);
      }).join(' ');
    }),
    modelNamePluralized: Ember.computed('createModel', function () {
      return (0, _emberInflector.pluralize)(this.get('createModel'));
    }),
    showContainer: true,
    // ---------------------- CREATE, EDIT, DELETE ----------------------
    create: function create(template) {
      var createPath = this.get('createPath');
      var newEntry = this.get('store').createRecord(this.get('createModel'));

      if (createPath != null) {
        this.get(createPath).pushObject(newEntry);
      }

      return this.edit(newEntry, template);
    },
    edit: function edit(entry, template) {
      var _this = this;

      this.resetForm();
      this.set('selected', entry);
      window.scrollTo(0, 0);
      return this.setForm(entry).then(function () {
        return _this.prefill(entry, template);
      });
    },
    "delete": function _delete() {
      var _this2 = this;

      if (this.confirmDelete()) {
        this.get('selected').destroyRecord().then(this.deleteComplete.bind(this)).then(this.notifyDeleteComplete.bind(this)).catch(function (error) {
          _this2.get('selected').rollbackAttributes();

          _this2.get('selected').reload();

          _this2.handleError(null, error);
        });
      }
    },
    confirmDelete: function confirmDelete() {
      return _environment.default.isTesting || confirm('Delete Entry?');
    },
    cancel: function cancel() {
      var entry = this.get('selected');
      entry.clearStates();
      this.clearForm(this.get('keepSelected'));
      this.notifyCancel(entry);
    },
    save: function save() {
      console.log('route-component save.');
      var entry = this.get('selected');
      var backup = this.createStateBackup(entry);
      var dependencyKeys = this.validationKeys(backup);
      entry.applyBuffers();

      if (this.checkValidation(entry, dependencyKeys)) {
        this.set('serverError', null);
        this.set('flashes', null);
        var promise = entry.save().then(this.handleSuccess.bind(this, backup)).catch(this.handleError.bind(this, backup));
        var promiseProxy = ObjectPromiseProxy.create({
          promise: promise
        });
        this.set('promise', promiseProxy);
        return promiseProxy;
      } else {
        this.showValidationErrors();
        this.resetState(backup);
      }
    },
    handleSuccess: function handleSuccess(backup) {
      console.log('route-component handleSuccess.');
      return Ember.RSVP.Promise.all(this.saveDependencies()).then(this.saveComplete.bind(this)).then(this.notifySaveComplete.bind(this)).catch(this.handleError.bind(this, backup));
    },
    handleError: function handleError(backup, xhr) {
      if (backup) {
        this.resetState(backup);
      }

      if (xhr) {
        this.showServerError(xhr);
      }
    },
    saveDependencies: function saveDependencies() {
      console.log('route-component saveDependencies method was called.'); //TODO SPLIT

      return [this.saveContacts(), this.destroyContacts(), this.saveAttachments(), this.destroyAttachments(), this.cleanupDepartments()];
    },
    resetState: function resetState(backup) {
      console.log('resetState');
      var entry;
      entry = this.get('selected');

      if (entry) {
        entry.resetDependencies(backup.belongsTo, backup.hasMany);
        entry.resetBuffers(backup.buffers);
      }
    },
    createStateBackup: function createStateBackup(entry) {
      var backupBuffers = entry.copyBufferState();
      var backupBelongsTo = entry.copyBelongsToState(backupBuffers);
      var backupHasMany = entry.copyHasManyState();
      return {
        buffers: backupBuffers,
        belongsTo: backupBelongsTo,
        hasMany: backupHasMany
      };
    },
    showServerError: function showServerError(xhr) {
      var response = xhr.responseJSON;

      if (response && response.error) {
        this.set('serverError', response.error);
      } else if (xhr.errors) {
        this.set('flashes', xhr.errors);
      }
    },
    // ---------------------- FLASH ALERTS + ERRORS ----------------------
    flashes: [],
    deleteComplete: function deleteComplete() {
      return this.clearForm(this.get('keepSelected'));
    },
    saveComplete: function saveComplete() {
      console.log('saveComplete');
      return this.clearForm(this.get('keepSelected'));
    },
    // ---------------------- NOTIFICATIONS ----------------------
    notifySaveComplete: function notifySaveComplete(entry) {
      console.log('notifySaveComplete');

      if (this.get('selected.supportsDirtyBufferTracking')) {
        var selected = this.get('selected');
        selected.set('hasJustSaved', true); //later(() => selected.set('hasJustSaved', false), 5000);
      }

      this.sendAction('saveCompleteAction', entry);
    },
    notifyDeleteComplete: function notifyDeleteComplete(entry) {
      this.sendAction('deleteCompleteAction', entry);
    },
    notifyCancel: function notifyCancel(entry) {
      this.sendAction('cancelCompleteAction', entry);
    },
    // ---------------------- FORM + HELPER FUNCTIONS ----------------------
    hasChanges: function hasChanges(entry) {
      return entry != null && (entry.get('isNew') || entry.get('isDirty'));
    },
    clearForm: function clearForm(keepSelected) {
      this.resetForm();
      var entry = this.get('selected');

      if (!keepSelected) {
        this.set('selected', null);
      }

      return entry;
    },
    resetForm: function resetForm() {
      this.resetEntry(this.get('selected'));
      this.resetAlerts();
    },
    resetEntry: function resetEntry(entry) {
      if (this.hasChanges(entry)) {
        entry.rollbackAttributes();
      }

      return entry != null ? entry.clearNewDependencies() : void 0;
    },
    resetAlerts: function resetAlerts() {
      return this.setProperties({
        serverSuccess: null,
        serverError: null,
        validationErrors: null,
        flashes: null
      });
    },
    setForm: function setForm(entry) {
      var waitForDependencies = entry.createDependencies();

      if (waitForDependencies instanceof Ember.RSVP.Promise) {
        return waitForDependencies.then(function () {
          return entry.createBuffers();
        });
      } else {
        return entry.createBuffers();
      }
    },
    prefill: function prefill(entry, template) {
      // TODO: prefillTemplate ?
      if (template instanceof _emberData.default.Model && entry.prefill) {
        entry.prefill(template);
      }

      return entry;
    },
    triggerActions: Ember.on('init', function () {
      this.triggerEditEntry(this.get('editModel'));
      this.triggerCreateEntry(this.get('createTemplate'));
    }),
    onCreateTemplate: Ember.observer('createTemplate', function () {
      this.triggerCreateEntry(this.get('createTemplate'));
    }),
    onEditModel: Ember.observer('editModel', function () {
      this.triggerEditEntry(this.get('editModel'));
    }),
    triggerCreateEntry: function triggerCreateEntry(template) {
      if (template) {
        this.triggerEntryAction('createEntry', template);
      }
    },
    triggerEditEntry: function triggerEditEntry(selected) {
      if (selected && selected instanceof _emberData.default.Model && selected !== this.get('selected')) {
        this.triggerEntryAction('editSelected', selected);
      }
    },
    triggerEntryAction: function triggerEntryAction(action, param) {
      var _this3 = this;

      this.setProperties({
        createTemplate: null,
        editModel: null
      });
      Ember.run(function () {
        _this3.send(action, param);
      });
    },
    createLinkedEntry: function createLinkedEntry(type) {
      switch (type) {
        case 'attachment':
          return this.addAttachment();

        case 'department':
          return this.addDepartment();

        default:
          return this.addContact();
      }
    },
    deleteLinkedEntry: function deleteLinkedEntry(linkedEntry) {
      var type = linkedEntry.get('content.constructor.modelName');

      switch (type) {
        case 'attachment':
          return this.removeAttachment(linkedEntry);

        case 'department':
          return this.removeDepartment(linkedEntry);

        default:
          return this.removeContact(linkedEntry);
      }
    },
    didInsertElement: function didInsertElement() {
      //this._super(...arguments);
      //this.trackCurrentModel();
      window.onbeforeunload = function () {
        return null;
      }; // Disable warning

    },
    trackCurrentModel: function trackCurrentModel() {
      this.get('currentUser').setProperties({
        currentModel: this.get('selected'),
        currentModelComponent: this.elementId
      });
    },
    releaseCurrentModel: function releaseCurrentModel() {
      var current = this.get('currentUser.currentModelComponent');

      if (!current || current === this.elementId) {
        this.get('currentUser').set('currentModel', null);
      }
    },
    willDestroy: function willDestroy() {
      //this._super(...arguments);
      //this.releaseCurrentModel();
      window.onbeforeunload = null; // Re-enable for other pages
    },
    actions: {
      createEntry: function createEntry(template) {
        var _this4 = this;

        return this.create(template).then(function () {
          _this4.trackCurrentModel();

          return _this4.get('selected');
        });
      },
      editSelected: function editSelected(entry) {
        var _this5 = this;

        if (entry && entry.reload) {
          return entry.reload().then(function (updatedEntry) {
            _this5.edit(updatedEntry);
          });
        }

        return this.edit(entry);
      },
      saveEntry: function saveEntry() {
        console.log('route-component saveEntry.');
        return this.save();
      },
      saveContacts: function saveContacts() {
        console.log('route-component saveContacts.');
        return Ember.RSVP.Promise.all(this.saveContacts()).then(this.saveComplete.bind(this)).then(this.notifySaveComplete.bind(this)).catch(this.handleError.bind(this, backup));
      },
      deleteEntry: function deleteEntry() {
        return this["delete"]();
      },
      cancelEdit: function cancelEdit() {
        return this.cancel();
      },
      createLinkedEntry: function createLinkedEntry(type, p1, p2) {
        return this.createLinkedEntry(type, p1, p2);
      },
      deleteLinkedEntry: function deleteLinkedEntry(linkedEntry) {
        return this.deleteLinkedEntry(linkedEntry);
      }
    }
  });

  _exports.default = _default;
});