define("backend/models/buffered-model", ["exports", "backend/utils/buffer-proxy", "ember-data", "backend/mixins/buffer-states-mixin", "backend/mixins/buffer-dependencies-mixin"], function (_exports, _bufferProxy, _emberData, _bufferStatesMixin, _bufferDependenciesMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_bufferStatesMixin.default, _bufferDependenciesMixin.default, {
    // ------------------- Manage buffered attachments -------------------------

    /**
     * Add buffered attachment
     */
    addAttachment: function addAttachment(uploadUrl) {
      var record = this.get("store").createRecord("attachment", {
        uploadUrl: uploadUrl
      });
      var buffer = (0, _bufferProxy.default)(record);
      this.get("buffers").attachments.pushObject(buffer);
    },

    /**
     * Remove buffered attachment
     */
    removeAttachment: function removeAttachment(buffer) {
      this.get("buffers").attachments.removeObject(buffer);
      buffer.discardChanges();
      this.get("deletedAttachments").pushObject(buffer.content);
    },

    /**
     * List of attachments to be deleted
     */
    deletedAttachments: Ember.computed(function () {
      return [];
    }),

    /**
     * Clear list of attachments to be deleted
     */
    clearAttachments: function clearAttachments() {
      this.set("deletedAttachments", null);
    },
    // --------------------- Manage buffered departments -----------------------
    addDepartment: function addDepartment() {
      var record = this.get("store").createRecord("department");
      var buffer = (0, _bufferProxy.default)(record);
      return this.get("buffers").departments.pushObject(buffer);
    },
    removeDepartment: function removeDepartment(buffer) {
      this.get("buffers").departments.removeObject(buffer);
      buffer.discardChanges();
    },
    // ----------------------- Managed buffered contacts -----------------------

    /**
     * Add a buffered contact
     */
    addContact: function addContact() {
      var buffer, record;
      record = this.get("store").createRecord("contact");
      buffer = (0, _bufferProxy.default)(record);
      return this.get("buffers").contacts.pushObject(buffer);
    },

    /**
     * Remove a buffered contact
     */
    removeContact: function removeContact(buffer) {
      console.log('buffered-model removeContact');
      this.get("buffers").contacts.removeObject(buffer);
      buffer.discardChanges();
      this.get("deletedContacts").pushObject(buffer.content);
    },

    /**
     * List of contacts to be deleted
     */
    deletedContacts: Ember.computed(function () {
      return [];
    }),

    /**
     * Clear list of contacts to be deleted
     */
    clearContacts: function clearContacts() {
      return this.set("deletedContacts", null);
    }
  });

  _exports.default = _default;
});