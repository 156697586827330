define("backend/mixins/form-contacts-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    addContact: function addContact() {
      this.get('selected').addContact();
    },
    removeContact: function removeContact(contact) {
      console.log('form-contacts-mixin removeContact');
      this.get('selected').removeContact(contact);
    },
    saveContacts: function saveContacts() {
      console.log('form-contacts-mixin saveContacts method was called.');
      var contacts = this.get('selected.contacts');
      console.log('form-contacts-mixin saveContacts contacts:', contacts);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!contacts || contacts.get('length') === 0) {
          return resolve();
        }

        var promises = [];
        contacts.forEach(function (record) {
          return promises.pushObject(record.save());
        }); // TODO: Add Flash

        Ember.RSVP.Promise.all(promises).then(function (contacts) {
          return resolve(contacts);
        }).catch(reject);
      });
    },
    saveContact: function saveContact(contact) {
      console.log('form-contacts-mixin saveContact method was called:', contact);
    },
    destroyContacts: function destroyContacts() {
      var contacts = this.get('selected.deletedContacts');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!contacts || contacts.get('length') === 0) {
          return resolve();
        }

        var promises = [];
        contacts.forEach(function (record) {
          return promises.pushObject(record.destroyRecord());
        }); // TODO: Add Flash

        Ember.RSVP.Promise.all(promises).then(function () {
          contacts.clear();
          return resolve();
        }).catch(reject);
      });
    }
  });

  _exports.default = _default;
});