define("backend/utils/static-data/department-sources", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [Ember.Object.create({
    groupName: 'Department Sources',
    options: ['Website', 'Patients', 'LinkedIn', 'Referal', 'Data Import', 'Inno Q2/22', 'Inno Q3/22', 'Inno Q1/23', 'EndoCert Q3/23', 'EndoCert Q4/23', 'Inno Q3/24', 'Inno Q4/24', 'Inno Q1/25'].map(function (name) {
      return Ember.Object.create({
        id: name,
        displayName: name
      });
    })
  })];
  _exports.default = _default;
});