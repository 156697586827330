define("backend/components/create-order", ["exports", "backend/components/route-component", "moment", "backend/models/order"], function (_exports, _routeComponent, _moment, _order) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var DeviceOrder = Ember.Object.extend({
    device_hardware_type: null,
    device_amount: null,
    device_type: null,
    recipient: null,
    department: null,
    contact_name: null,
    contact_phone: null,
    contact_email: null,
    delivery_date: null,
    delivery_flexible: false,
    delivery_type: null,
    notes: null,
    ceo_reference: null,
    headphone_covers_text: null,
    hospital_folder_text: 'Anesthesia onepager',
    patient_leaflet_text: null,
    innovation_report_text: null,
    content_packages: Ember.computed(function () {
      return [];
    }),
    modifications: Ember.computed(function () {
      return [];
    }),
    attachments: Ember.computed(function () {
      return [];
    }),
    documents: Ember.computed(function () {
      return [];
    }),
    save: function save(store, packages, contract) {
      var deliveryDate = this.get('delivery_date');

      if (deliveryDate) {
        deliveryDate = (0, _moment.default)(deliveryDate).format('YYYY-MM-DD');
      }

      var recipient = this.get('recipient');
      var department = this.get('department');
      var recipientObj;

      if (recipient) {
        recipientObj = {
          id: recipient.get('id'),
          type: recipient.get('typeName'),
          displayName: recipient.get('displayName')
        };
      }

      if (department) {
        recipientObj.department_id = department.get('id');
        recipientObj.department_name = department.get('displayName');
      }

      var attachments = _toConsumableArray(this.attachments);

      if (this.headphone_covers_text && this.attachments.includes('Headphone Covers')) {
        attachments.removeObject('Headphone Covers');
        attachments.pushObject("Headphone Covers (".concat(this.headphone_covers_text, ")"));
      }

      if (this.hospital_folder_text && this.attachments.includes('Hospital Folder')) {
        attachments.removeObject('Hospital Folder');
        attachments.pushObject("Hospital Folder (".concat(this.hospital_folder_text, ")"));
      }

      if (this.patient_leaflet_text && this.attachments.includes('Patient Leaflets')) {
        attachments.removeObject('Patient Leaflets');
        attachments.pushObject("Patient Leaflets (".concat(this.patient_leaflet_text, ")"));
      }

      if (this.innovation_report_text && this.attachments.includes('Innovation Report')) {
        attachments.removeObject('Innovation Report');
        attachments.pushObject("Innovation Report (".concat(this.innovation_report_text, ")"));
      }

      return store.adapterFor('application').createDeviceOrder({
        device_amount: Number(this.get('device_amount')),
        device_type: this.get('device_type'),
        device_hardware_type: this.get('device_hardware_type'),
        recipient: recipientObj,
        contact_name: this.get('contact_name'),
        contact_phone: this.get('contact_phone'),
        contact_email: this.get('contact_email'),
        delivery_date: deliveryDate,
        delivery_flexible: this.get('delivery_flexible'),
        delivery_type: this.get('delivery_type'),
        ceo_reference: this.get('ceo_reference'),
        content_packages: this.get('content_packages').map(function (name) {
          return {
            id: packages.findBy('name', name).get('id'),
            displayName: name
          };
        }),
        // modifications: this.get('modifications'),
        attachments: attachments,
        documents: this.get('documents'),
        notes: this.get('notes'),
        contract: contract
      });
    }
  });

  function selOptions(arr) {
    return arr.map(function (value) {
      return Ember.Object.create({
        id: value,
        displayName: value
      });
    });
  }

  var _default = _routeComponent.default.extend({
    templateEdit: 'components/forms/create-order',
    isPending: false,
    options: {
      deviceAmounts: [Ember.Object.create({
        id: 1,
        displayName: '1 Device'
      }), Ember.Object.create({
        id: 2,
        displayName: '2 Devices'
      }), Ember.Object.create({
        id: 3,
        displayName: '3 Devices'
      }), Ember.Object.create({
        id: 4,
        displayName: '4 Devices'
      }), Ember.Object.create({
        id: 5,
        displayName: '5 Devices'
      }), Ember.Object.create({
        id: 6,
        displayName: '6 Devices'
      }), Ember.Object.create({
        id: 7,
        displayName: '7 Devices'
      }), Ember.Object.create({
        id: 8,
        displayName: '8 Devices'
      }), Ember.Object.create({
        id: 9,
        displayName: '9 Devices'
      }), Ember.Object.create({
        id: 10,
        displayName: '10 Devices'
      })],
      deviceTypes: selOptions(_order.ORDER_DEVICE_TYPES),
      deliveryTypes: selOptions(['Personal Delivery', 'Shipping (UPS)']),
      hardwareTypes: selOptions(['Video Glass', 'Easy Info Tablet', 'Easy Movie Tablet', 'EasyTv', 'hypnoVR'])
    },
    allModifications: ['Shortened Temples', 'On/Off Switch Earphones'],
    allAttachments: ['Headphone Covers', 'User Manual', 'Short Instruction Sheet', 'Hospital Folder', 'Patient Leaflets', 'Evaluation Form', 'Innovation Report', 'Content Menu', 'A4-Plakat', 'EasyMovie Tablet + Headphones', 'Spider Stand', 'Letter from the CEO', 'STK'],
    allDocuments: ['Delivery Note', 'Return Label'],
    hasLetterFromTheCeo: Ember.computed('order.attachments.length', function () {
      return this.get('order.attachments').includes('Letter from the CEO');
    }),
    hasHeadphoneCovers: Ember.computed('order.attachments.length', function () {
      return this.get('order.attachments').includes('Headphone Covers');
    }),
    hasInnovationReport: Ember.computed('order.attachments.length', function () {
      return this.get('order.attachments').includes('Innovation Report');
    }),
    hasPatientLeaflets: Ember.computed('order.attachments.length', function () {
      return this.get('order.attachments').includes('Patient Leaflets');
    }),
    hasHospitalFolder: Ember.computed('order.attachments.length', function () {
      return this.get('order.attachments').includes('Hospital Folder');
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var order = DeviceOrder.create({
        name: this.get('currentUser.fullName'),
        modifications: ['Shortened Temples', 'On/Off Switch Earphones'],
        attachments: ['Headphone Covers', 'User Manual', 'Short Instruction Sheet'],
        documents: ['Delivery Note']
      });
      var contract = this.get('data.contract');

      if (contract) {
        order.setProperties({
          device_amount: contract.get('numDevices'),
          device_type: 'Client Sale',
          recipient: contract.get('client'),
          department: contract.get('department'),
          content_packages: [contract.get('package.name')]
        });
      }

      this.set('order', order);

      if (this.data.owner) {
        Ember.run.schedule('afterRender', function () {
          order.set('recipient', _this.data.owner);

          _this.actions.selectRecipient.bind(_this)(_this.data.owner);
        });
      }
    },
    orderDevice: function orderDevice() {
      var _this2 = this;

      this.set('isPending', true);
      this.get('flashes').clear();
      this.get('order').save(this.get('store'), this.get('data.packages'), this.get('data.contract.id')).then(function (_ref) {
        var order = _ref.data.order;

        var flashes = _this2.get('flashes');

        var succFlash = {
          alert: "Order #".concat(order, " has been created!")
        };
        flashes.pushObject(succFlash);
        Ember.run.later(function () {
          if (flashes) {
            flashes.removeObject(succFlash);
          }
        }, 10000);
      }).catch(function (xhr) {
        if (xhr && xhr.errors) {
          var flashes = _this2.get('flashes');

          var errFlashes = xhr.errors;
          flashes.pushObjects(errFlashes);
          Ember.run.later(function () {
            if (flashes) {
              flashes.removeObjects(errFlashes);
            }
          }, 10000);
        } else {
          throw xhr;
        }
      }).finally(function () {
        return _this2.set('isPending', false);
      });
    },
    ownerContacts: Ember.computed('order.recipient.id', function () {
      return this.get('order.recipient.contacts');
    }),
    customContacts: Ember.computed(function () {
      return [];
    }),
    contacts: Ember.computed.union('ownerContacts', 'customContacts'),
    setDeliveryFlexible: function setDeliveryFlexible(order, event) {
      order.set('delivery_flexible', event.target.checked);
    },
    actions: {
      orderDevice: function orderDevice() {
        return this.orderDevice();
      },
      selectPurpose: function selectPurpose(purpose) {
        if (purpose.get('id') === 'Client Sale') {
          if (!this.get('order.attachments').includes('Letter from the CEO')) {
            this.get('order.attachments').pushObject('Letter from the CEO');
          }
        }
      },
      selectRecipient: function selectRecipient(recipient) {
        var packages = this.get('order.content_packages');

        if (!recipient || !packages || packages.get('length') !== 0) {
          return false;
        }

        var country = recipient && recipient.get('address.country');

        if (!country) {
          return false;
        }

        var matchBy = "".concat(country, " extended");

        if (country === 'at') {
          matchBy = 'de extended';
        }

        if (country === 'gb') {
          matchBy = 'uk extended';
        }

        var found = this.get('data.packages').filter(function (pack) {
          return pack.get('name').toLowerCase() === matchBy;
        });
        packages.pushObjects(found.mapBy('name'));

        if (recipient.departments && recipient.departments.length === 1) {
          this.order.set('department', recipient.departments.toArray()[0]);
        } else {
          this.order.set('department', null);
        }
      },
      selectContact: function selectContact(contact) {
        if (contact) {
          var _contact$getPropertie = contact.getProperties('phone', 'email'),
              phone = _contact$getPropertie.phone,
              email = _contact$getPropertie.email;

          this.get('order').setProperties({
            contact_phone: phone,
            contact_email: email
          });
        }
      },
      createContact: function createContact(name) {
        if (name) {
          this.get('customContacts').pushObject(Ember.Object.create({
            displayName: name
          }));
          this.get('order').setProperties({
            contact_name: name,
            contact_phone: null,
            contact_email: null
          });
        }
      }
    }
  });

  _exports.default = _default;
});