define("backend/pods/components/hm-model/fieldset-essentials/company-w-departments-sponsored/component", ["exports", "backend/pods/components/hm-model/mixins/department-status", "backend/pods/components/hm-model/mixins/department-won-lost"], function (_exports, _departmentStatus, _departmentWonLost) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_departmentStatus.default, _departmentWonLost.default, {
    tagName: 'fieldset',
    store: Ember.inject.service(),
    actions: {
      blank: function blank() {}
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      console.log('didInsertElement'); // Bind the model so it’s accessible within the event listener

      var checkbox = document.getElementById("synced_checkbox");

      if (checkbox) {
        checkbox.addEventListener("click", function () {
          _this.set('model.syncedToClose', checkbox.checked); // Save the model


          try {
            _this.model.save(); //alert('Model saved successfully');


            _this.showToast('Saved', 'success');
          } catch (error) {
            console.error("Failed to save model", error); //alert('Failed to save model');
          }
        });
      }

      var reviewed_checkbox = document.getElementById("reviewed_checkbox");

      if (reviewed_checkbox) {
        reviewed_checkbox.addEventListener("click", function () {
          _this.set('model.toBeReviewed', reviewed_checkbox.checked); // Save the model


          try {
            _this.model.save(); //alert('Model saved successfully');


            _this.showToast('Saved', 'success');
          } catch (error) {
            console.error("Failed to save model", error); //alert('Failed to save model');
          }
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var checkbox = document.getElementById("synced_checkbox");

      if (checkbox) {
        checkbox.removeEventListener("click", this.handleCheckboxClick);
      }

      var reviewed_checkbox = document.getElementById("reviewed_checkbox");

      if (reviewed_checkbox) {
        reviewed_checkbox.removeEventListener("click", this.handleCheckboxClick);
      }
    },
    showToast: function showToast(message, type) {
      var toast = document.createElement('div');
      toast.className = "toast-message ".concat(type);
      toast.innerText = message;
      document.body.appendChild(toast);
      setTimeout(function () {
        toast.remove();
      }, 3000);
    }
  });

  _exports.default = _default;
});