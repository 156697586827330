define("backend/models/lead", ["exports", "ember-data", "backend/utils/cp/buffer-changes", "backend/helpers/formatters/date", "backend/models/client-like"], function (_exports, _emberData, _bufferChanges, _date, _clientLike) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  var BUFFER_BELONGS_TO = ['address'];
  var BUFFER_HAS_MANY = ['contacts', 'departments'];

  var _default = _clientLike.default.extend({
    meta: attr(),
    offers: hasMany('offer', {
      async: true
    }),
    leadType: belongsTo('leadType', {
      async: false
    }),
    lostReason: belongsTo('reason', {
      async: false
    }),
    tipster: attr('string'),
    bestReachability: attr('string'),
    syncedToClose: attr('number'),
    toBeReviewed: attr('number'),
    sponsorship: attr('string'),
    priority: belongsTo('priority', {
      async: false
    }),
    markerIcon: Ember.computed('address.isCountryOnly', 'priority.markerSuffix', 'status.markerPrefix', function () {
      if (this.get('address.isCountryOnly')) {
        return new google.maps.MarkerImage("/assets/hm_marker.png", new google.maps.Size(40, 40), new google.maps.Point(0, 0));
      }
    }),
    markerInfo: Ember.computed('displayName', 'priority.id', 'departments.length', 'hasNoAddress', 'distributor.displayName', function () {
      var info = [];
      info.push(['<p class="text-bold">', this.get('displayName'), '</p>'].join(''));
      info.push(['<ul>'].join(''));
      info.push(this.get('departments').reduce(function (html, department) {
        html.push(['<li><p>', department.get('name'), ': ', '<span class="text-bold">', department.get('status.displayName'), '</span>', '</p>'].join(''));
        var note = department.get('statusNote');

        if (note) {
          if (department.get('status.needsDate')) {
            html.push(['<p>Note: ', (0, _date.default)([note]), '</p>'].join(''));
          } else if (department.get('status.needsDateRangeAndDevices')) {
            if (note.indexOf(';') > -1) {
              var _note$split = note.split(';'),
                  _note$split2 = _slicedToArray(_note$split, 2),
                  startDate = _note$split2[0],
                  endDate = _note$split2[1];

              html.push(['<p>Note: ', (0, _date.default)([startDate]), ' to ', (0, _date.default)([endDate]), '</p>'].join(''));
            }

            var numDevices = department.get('numDevices');

            if (numDevices) {
              html.push(['<p>Devices: ', numDevices, '</p>'].join(''));
            }
          } else if (department.get('status.needsReason') || department.get('status.needsNumOfDevices')) {
            html.push(['<p>Note: ', note, '</p>'].join(''));
          }
        }

        html.push(['</li>']);
        return html;
      }, []).join(''));
      info.push(['</ul><br />'].join(''));

      if (this.get('priority.id')) {
        info.push(['<p>Priority: ', '<span class="text-bold">', this.get('priority.displayName'), '</span>', '</p>'].join(''));
      }

      if (this.get('address.isCountryOnly')) {
        info.push(['<p>No address specified.</p>'].join(''));
      }

      info.push(['<p>Distributor: ', this.get('distributor.displayName'), '</p>'].join(''));
      return Ember.String.htmlSafe(info.join(''));
    }),
    trialAgo: Ember.computed('departments.@each.trialEndDate', function () {
      return this.get('departments').filter(function (d) {
        return !!d.get('trialEndDate');
      }).map(function (d) {
        var date = d.get('trialEndDate');

        if (!date) {
          return null;
        }

        return moment(date).format('YYYY-MM-DD') + " (".concat(moment(date).fromNow(), ")");
      });
    }),
    hasBufferedChangesContacts: (0, _bufferChanges.watchBufferedHasMany)('contacts'),
    hasBufferedChangesDepartments: (0, _bufferChanges.watchBufferedHasMany)('departments'),
    hasBufferedChanges: (0, _bufferChanges.watchBufferChanges)(BUFFER_BELONGS_TO, BUFFER_HAS_MANY),
    typeName: Ember.computed('isClientGroup', function () {
      return this.get('isClientGroup') ? 'Group Lead' : 'Lead';
    }),
    ownerType: 'lead'
  });

  _exports.default = _default;
});