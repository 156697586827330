define("backend/mixins/form-departments-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    addDepartment: function addDepartment() {
      this.get('selected').addDepartment();
    },
    removeDepartment: function removeDepartment(department) {
      this.get('selected').removeDepartment(department);
    },
    saveDepartment: function saveDepartment(department) {
      this.get('selected').saveDepartment(department);
    },
    cleanupDepartments: function cleanupDepartments() {
      var departments = this.get('selected.departments');

      if (departments) {
        var newDepartments = departments.filterBy('isNew');

        if (newDepartments.get('length') > 0) {
          newDepartments.forEach(function (department) {
            return department.rollbackAttributes();
          });
          this.set('selected.buffers.departments', null);
        }
      }

      return Ember.RSVP.resolve();
    }
  });

  _exports.default = _default;
});